<template>
  <div class="flex flex-column justify-content-center align-items-center">
    <slot name="message">
      <div class="text-center" v-html="message"></div>
    </slot>
    <slot>
      <div class="flex justify-content-center text-center mt-6">
        <form autocomplete="off" aria-autocomplete="off">
          <label
            style="color: #e0e0e0"
            class="font-normal text-center font-semibold"
            >Ingrese el código <sup>*</sup></label
          >
          <div class="flex justify-content-center flex-nowrap mb-3">
            <VuePincodeInput
              v-model="validation_code"
              :digits="code_digits"
              inputClass="flex justify-content-center flex-nowrap border-noround text-300 text-xl font-semibold text-center"
              :autofocus="true"
              :disabled="sending_validation_code"
              :key="key"
            />
          </div>
          <span style="color: #e0e0e0" class="text-highlight text-xs mb-5"
            ><sup>*</sup> Ingrese los
            {{ convertNumberToLetters(code_digits) }} dígitos del código de
            validación</span
          >
          <div class="flex mt-5">
            <Button
              label="Cancelar"
              class="p-button-outlined text-white mt-2 mr-4"
              @click="$emit('cancel')"
              v-if="show_cancel_button"
              :disabled="sending_validation_code"
            />
            <Button
              label="Continuar"
              class="p-button mt-2"
              @click="sendValidationCode"
              v-if="show_confirm_button"
              :loading="sending_validation_code"
            />
          </div>
        </form>
      </div>
    </slot>
  </div>
</template>

<script>
import VuePincodeInput from "vue3-pincode-input";
export default {
  components: {
    VuePincodeInput,
  },
  props: {
    message: {
      type: String,
      default: "Ingrese el código de validación",
    },
    code_digits: {
      type: Number,
      default: 6,
    },
    sending_validation_code: {
      type: Boolean,
      default: false,
    },
    show_cancel_button: {
      type: Boolean,
      default: true,
    },
    show_confirm_button: {
      type: Boolean,
      default: true,
    },
    auto_send_code: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      validation_code: "",
      key: 0,
    };
  },
  methods: {
    sendValidationCode() {
      if (!this.checkCode) return;
      this.$emit("sendValidationCode", this.checkCode);
    },
    clearValidationCode() {
      this.validation_code = "";
      this.key++;
    },
    convertNumberToLetters(numero) {
      const unidades = [
        "",
        "uno",
        "dos",
        "tres",
        "cuatro",
        "cinco",
        "seis",
        "siete",
        "ocho",
        "nueve",
      ];
      const decenas = [
        "",
        "diez",
        "veinte",
        "treinta",
        "cuarenta",
        "cincuenta",
        "sesenta",
        "setenta",
        "ochenta",
        "noventa",
      ];
      const especiales = [
        "once",
        "doce",
        "trece",
        "catorce",
        "quince",
        "dieciséis",
        "diecisiete",
        "dieciocho",
        "diecinueve",
      ];
      let resultado = "";
      if (numero == 0) {
        resultado = "cero";
      } else if (numero < 10) {
        resultado = unidades[numero];
      } else if (numero < 20) {
        resultado = especiales[numero - 11];
      } else if (numero < 100) {
        resultado = decenas[Math.floor(numero / 10)];
        if (numero % 10 !== 0) {
          resultado += " y " + unidades[numero % 10];
        }
      } else {
        resultado = "Número fuera de rango";
      }
      return resultado;
    },
  },
  computed: {
    checkCode() {
      if (
        this.validation_code.length < this.code_digits ||
        isNaN(this.validation_code)
      )
        return false;
      else return this.validation_code;
    },
  },
  watch: {
    validation_code(value) {
      if (value && this.auto_send_code && this.checkCode) {
        this.sendValidationCode();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/_variables.scss";

.buttons-container {
  display: flex;
  justify-content: center;

  .p-button {
    color: $magenta;
    border: solid 1px $magenta;
    border-radius: 6px;
    background: #fff;
    max-width: fit-content;
    min-width: 50px;
    padding: 10px 15px;

    &.main {
      background: $magenta;
      color: #fff;
    }

    &.confirm {
      background: $magenta;
      color: #fff;
      height: 35px;
    }
  }

  .p-button:hover,
  .p-button:active {
    background: $magenta_dark;
    color: #fff;
  }
}

.vue-pincode-input-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  row-gap: 1rem;
  margin-top: 10px;

  :deep(input.vue-pincode-input) {
    max-width: 40px;
    margin-right: 14px;
    height: 50px;
    border-radius: 6px !important;
    border: 1px solid #fff;
    background: transparent;
  }
}

.vue-pincode-input-wrapper[disabled="true"] {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

:deep(a) {
  color: $magenta;
  font-weight: 600;

  &:hover {
    color: $magenta_dark;
  }
}

:deep(a[disabled="1"]) {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
</style>
